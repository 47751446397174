<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-fade-transition v-if="loggedInAndIsAdmin" mode="out-in">
        <router-view/>
      </v-fade-transition>
      <div v-else>
        <section class="h-100">
          <div class="container h-100">
            <div class="row justify-content-sm-center h-100">
              <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                <div class="text-center my-5">
                  <img style="width: 174px;"
                       src="/logo.png"
                       alt="logo" width="100"
                  >
                </div>
                <div class="card shadow-lg">
                  <div class="card-body p-5">
                    <h1 class="fs-4 card-title fw-bold mb-4">Login</h1>
                    <form class="needs-validation">
                      <div class="mb-3">
                        <label class="mb-2 text-muted" for="email">E-Mail Address</label>
                        <input id="email" type="email" v-model="email" class="form-control" name="email" value=""
                               required
                               autofocus
                        >
                        <div class="invalid-feedback">
                          Email is invalid
                        </div>
                      </div>

                      <div class="mb-3">
                        <label class="mb-2 text-muted" for="email">Password</label>
                        <input id="password" type="password" v-model="password" class="form-control" name="password"
                               required
                        >
                        <div class="invalid-feedback">
                          Password is required
                        </div>
                      </div>

                      <div class="d-grid gap-2">
                        <button @click="loginWithCredentials" class="btn btn-primary text-white" type="button">Login
                        </button>
                        <button @click="loginWithGoogle" class="mt-2 btn btn-light" type="button">Login via google <img
                          style="margin-left: 10px;" src="/google.svg" alt="google logo"
                        ></button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="text-center mt-5 text-muted">
                  Copyright &copy; <span>{{ year }}</span> &mdash; Stomerij Etten-leur
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth'

  export default {
    name: 'App',
    data () {
      this.loading = true
      const user = this.$fsAuth.currentUser
      if (!user) {
        this.loading = false
      } else {
        this.$auth.getData('users', user.uid).then(fsUser => {
          this.loading = false
          if (fsUser.role === 'admin') {
            this.loggedInAndIsAdmin = true
          }
        })
      }

      return {
        loggedInAndIsAdmin: null,
        loading: null,
        year: new Date().getFullYear(),
        email: '',
        password: '',
      }
    },
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Stomerij Etten-leur',
      htmlAttrs: { lang: 'nl' },
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
      ],
    },
    methods: {
      loginWithCredentials () {
        if (this.email.trim() === '' || this.password.trim() === '') return
        signInWithEmailAndPassword(this.$fsAuth, this.email, this.password)
          .then((userCredential) => {
            window.location.reload()
          }).catch(err => {
          if (err.code.includes('wrong')) {
            return this.$swal({
              icon: 'error',
              title: 'Authentisering fout',
              text: 'Email of/en wachtwoord zijn verkeerd',
            })
          }
          this.$swal({
            icon: 'error',
            title: 'error',
            text: 'something went wrong',
          })
        })
      },
      loginWithGoogle () {
        const provider = new GoogleAuthProvider()
        signInWithPopup(this.$fsAuth, provider)
          .then((result) => {
            window.location.reload()
          })
      },
    },
  }
</script>
