// Imports
import Vue from "vue";
import Router from "vue-router";
import { trailingSlash } from "@/util/helpers";
import { layout, route } from "@/util/routes";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    layout("Default", [
      route("Dashboard"),

      // Pages
      // route("UserProfile", null, "components/profile"),

      // Users
      route("Gebruikers", null, "users"),
      route("GebruikerDetail", null, "users/:userUid"),

      // orders
      route("Bestellingen", null, "orders"),
      route("BestellingenDetail", null, "orders/:groupId"),

      // products
      route("ProductenDocument", null, "products"),
      route("Producten", null, "products/:document"),
      route("ProductDetail", null, "products/:document/:productId"),

      // section
      route("Vakken", null, "section"),
      route("VakkenDetail", null, "section/:section"),

      // subscriptions
      route("Subscriptions", null, "subscriptions"),
      route("SubscriptionsDetail", null, "subscriptions/:subscriptionId"),

      // Timing
      route("TimeBlock", null, "timeBlock"),
      route("TimeBlockDetail", null, "timeBlock/:timeBlockId"),

      // Tables
      // route("Regular Tables", null, "tables/regular"),

      // Components
      // route("Notifications", null, "components/notifications"),
      // route("Icons", null, "components/icons"),
      // route("Typography", null, "components/typography"),

      // Maps
      // route("Google Maps", null, "maps/google"),

      // 404
      route("Error", null, "*")
    ])
  ]
});

router.beforeEach((to, from, next) => {
  return to.path.endsWith("/") ? next() : next(trailingSlash(to.path));
});

export default router;
