import formatCurrency from 'format-num'
import Compressor from 'compressorjs'

class FilterBase {
  constructor () {
    this.months = [
      'Jan',
      'Feb',
      'Mrt',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec',
    ]
  }

  createFulldate = dateField => {
    let d = ''
    if (dateField.seconds) {
      d = new Date(dateField.seconds * 1000)
    } else {
      d = new Date(dateField)
    }
    const year = d.getFullYear()
    const date = d.getDate()
    const month = this.months[d.getMonth()]
    const fullDate = `${date} ${month} ${year}`
    return fullDate
  }

  formatCurrency (number) {
    const opts = {
      code: 'EUR',
      symbol: '€',
      locale: 'nl-NL',
      maxFraction: 2,
      minFraction: 2,
    }
    const formatted = formatCurrency(number, opts)
    return `${opts.symbol} ${formatted}`
  }

  filterOrderGroups (orderGroups) {
    return orderGroups.map(orderGroup => {
      return {
        orders: orderGroup.orders.filter(order => order.id !== 'ZENDING_KOSTEN').map(order => order.name).join(', '),
        date: this.createFulldate(orderGroup.date),
        groupId: orderGroup.groupId,
        code: orderGroup.code,
        price: this.formatCurrency(orderGroup.totalPrice),
        count: orderGroup.orders.map(order => order.count !== '--' ? order.count : 0).reduce((ac, orderGroup) => ac + orderGroup, 0),
      }
    })
  }

  filterGroupedOrders (orderGroup) {
    const orders = orderGroup.orders
    return this.filterOrders(orders)
  }

  filterOrder (order) {
    return {
      ...order,
      priceNumber: order.price,
      date: order.date ? this.createFulldate(order.date) : '',
      price: order.priceFixer ? order.priceFixer + ' ' + this.formatCurrency(order.price) : this.formatCurrency(order.price),
    }
  }

  formatProductBeforeStoring (product) {
    const output = {
      ...product,
      price: product.priceNumber,
    }
    delete output.priceNumber
    return output
  }

  filterOrders (orders) {
    return orders.map(el => this.filterOrder(el))
  }

  filterCollection (collectionsWithData) {
    const keys = Object.keys(collectionsWithData)
    return Object.values(collectionsWithData).map((el, index) => {
      const object = {}
      object['group'] = keys[index]
      object['count'] = el.length
      return object
    })
  }

  filterStoreUser (user) {
    return {
      ...user,
      uid: user.uid,
      name: user.user.name ? user.user.name : 'geen',
      email: user.user.email ? user.user.email : 'geen',
      phoneNumber: user.user.phoneNumber ? user.user.phoneNumber : 'geen',
      ordersRefs: user.ordersRefs,
      model: {
        ...user,
        uid: user.uid,
        name: user.user.name ? user.user.name : 'geen',
        email: user.user.email ? user.user.email : 'geen',
        phoneNumber: user.user.phoneNumber ? user.user.phoneNumber : 'geen',
        ordersRefs: user.ordersRefs,
      },
      user: {
        ...user.user,
        name: user.user.name ? user.user.name : 'geen',
        email: user.user.email ? user.user.email : 'geen',
        phoneNumber: user.user.phoneNumber ? user.user.phoneNumber : 'geen',
      },
    }
  }

  filterStoreUsers (users) {
    return users.map((user) => this.filterStoreUser(user))
  }

  filterProduct (order) {
    return {
      ...order,
      priceNumber: order.price,
      price: order.priceFixer ? order.priceFixer + ' ' + this.formatCurrency(order.price) : this.formatCurrency(order.price),
    }
  }

  filterProducts (orders) {
    return orders.map(order => this.filterProduct(order))
  }

  filterSubscription (subscription) {
    const startSeconds = subscription?.start?.seconds
    const endSeconds = subscription?.end?.seconds
    const currentSeconds = (new Date()).getTime() / 1000
    let status = '-'
    if (!subscription.activated) {
      status = 'nog niet actief'
    } else if (startSeconds <= currentSeconds && endSeconds >= currentSeconds) {
      status = 'active'
    } else if (endSeconds < currentSeconds) {
      status = 'niet meer actief'
    }

    return {
      ...subscription,
      endDate: subscription.endDate ? subscription.endDate : 'nvt',
      status,
      userName: subscription.user.name,
    }
  }

  filterSubscriptions (subscriptions) {
    return subscriptions.map(subscription => this.filterSubscription(subscription))
  }

  async compressProductImage (blob,cl) {
    return new Compressor(blob, {
      quality: 0.7,
      convertSize: 0,
      convertTypes: ['image/png', 'image/webp'],
      height: 550,
      width: 425,
      resize: 'cover',
      success (result) {
        cl(result)
      },
      error (err) {
        throw new Error(`error from compressor js err: ${err}`)
      },
    })
  }
}

export default FilterBase
