import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import StomerijAuth from './classes/auth/StomerijAuth'
import Filters from './classes/filters/FilterBase'
import formatCurrency from 'format-num'
import VueQrcodeReader from 'vue-qrcode-reader'
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VCalendar from 'v-calendar'
import VueFormulate from '@braid/vue-formulate'
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
import firebaseConfig from './classes/auth/firebaseConfig.js'

import { initializeApp } from 'firebase/app'
initializeApp(firebaseConfig)
import { getAuth, onAuthStateChanged} from 'firebase/auth'

const Auth = new StomerijAuth()
const ObjectFilters = new Filters()
const fsAuth = getAuth()
Vue.config.productionTip = false
Vue.prototype.$auth = Auth
Vue.prototype.$fsAuth = fsAuth
Vue.prototype.$filter = ObjectFilters
Vue.prototype.$formatCurrency = formatCurrency
Vue.use(VueQrcodeReader).use(VueFormGenerator).use(VueSweetalert2).use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
}).use(VueFormulate)

Vue.extend({
  template: '...',
})
let app;

onAuthStateChanged(fsAuth, (user) => {
  if (!app) {
    sync(store, router)
    new Vue({
      router,
      vuetify,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
});
