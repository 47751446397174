import Auth from './Auth.js'
import axios from "axios";

class StomerijAuth extends Auth {
  #defaultUserData

  constructor () {
    super()
    this.#defaultUserData = {
      address: {
        formatted: '',
        geometry: {},
      },
      orders: [],
      memberschips: [],
    }
    this.months = [
      'Jan',
      'Feb',
      'Mrt',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec',
    ]
  }

  // Dates
  createFulldate = dateField => {
    let d = ''
    if (dateField.seconds) {
      d = new Date(dateField.seconds * 1000)
    } else {
      d = new Date(dateField)
    }
    const year = d.getFullYear()
    const date = d.getDate()
    const month = this.months[d.getMonth()]
    const fullDate = `${date} ${month} ${year}`
    return fullDate
  }

  // Products
  #getInProducts = async (collName, fieldName = undefined) => {
    const data = await this.getData('products', collName, fieldName)
    return data
  }

  // getReiniging = async () => {
  //   const reiniging = await this.#getInProducts('reiniging', 'data')
  //   return reiniging
  // }
  // getReparatie = async () => {
  //   const reparatie = await this.#getInProducts('reparatie', 'data')
  //   return reparatie
  // }
  // getOther = async () => {
  //   const other = await this.#getInProducts('other', 'data')
  //   return other
  // }

  // Orders
  #getInOrders = async (collName, fieldName = undefined) => {
    const data = await this.getData('orders', collName, fieldName)
    return data
  }

  getClosedOrderGroups = async () => {
    const closedOrdersGroups = await this.#getInOrders('closed', 'orderGroups')
    return closedOrdersGroups
  }
  getOpenOrderGroups = async () => {
    const openOrdersGroups = await this.#getInOrders('open', 'orderGroups')
    return openOrdersGroups
  }
  getInterruptedOrderGroups = async () => {
    const interruptedOrderGroups = await this.#getInOrders('interrupted', 'orderGroups')
    return interruptedOrderGroups
  }
  getAbonnementen = async () => {
    const abonnementenOrders = await this.#getInOrders('abonnementen', 'orderGroups')
    return abonnementenOrders
  }

  getAllOrderGroups = async () => {
    const openOrderGroups = await this.getOpenOrderGroups()
    const closedOrderGroups = await this.getClosedOrderGroups()
    const interruptedOrderGroups = await this.getInterruptedOrderGroups()
    const abonnementen = await this.getAbonnementen()
    return [...openOrderGroups, ...closedOrderGroups, ...interruptedOrderGroups, ...abonnementen]
  }
  getProductsBySection = async (section) => {
    const allProducts = Object.values(await this.getProducts()).flat()
    return allProducts.filter(product => product.section === section)
  }

  getAllSections = async () => {
    const allProducts = Object.values(await this.getProducts()).flat()
    const allSections = [...(new Set(allProducts.map(product => product.section)))]
    const data = allSections.map(section => {
      return {
        count: allProducts.filter((product) => product.section === section).length,
        section,
      }
    })
    return data
  }

  getOrderGroup = async (groupId) => {
    const orderGroups = await this.getAllOrderGroups()
    const foundedGroups = orderGroups.find(el => el.groupId == groupId)
    return foundedGroups
  }
  getSomething = async (id) => {
    const orderGroups = await this.getAllOrderGroups()
    const subscriptions = await this.getAllSubscriptions()
    const data = [...orderGroups, ...subscriptions]
    return data.filter(el => el.id === id || el.groupId === id)
  }

  async getAllSubscriptions () {
    return []
  }

  // Users
  #getInStoreUsers = async (collName, fieldName = undefined) => {
    const data = await this.getData('users', collName, fieldName)
    return data
  }
  getStoreUser = async uid => {
    const user = await this.#getInStoreUsers(uid)
    return user
  }
  getStoreUsers = async () => {
    const usersCollection = await this.getDocs('users')
    const users = []
    usersCollection.forEach(doc => {
      users[doc.id] = doc.data()
      users[doc.id].uid = doc.id
    })
    return users
  }
  setStoreUser = async (uid, data = {}) => {
    await this.setDoc('users', uid, data)
  }

  getGroupedOrders = async () => {
    const groups = []
    const res = await this.getOrders()
    res.forEach(el => {
      const fullDate = this.createFulldate(el.date)
      const groupId = el.groupId
      const foundedGroup = groups.find(el => el.groupId == groupId)

      if (foundedGroup) {
        foundedGroup.orders.push(el)
      } else {
        groups.push({
          secondsDate: el.date.seconds,
          status: el.status,
          groupId: groupId,
          date: fullDate,
          orders: [el],
        })
      }
    })
    groups.sort((a, b) => new Date(b.secondsDate) - new Date(a.secondsDate))

    return groups
  }
  setOrderGroups = async (newOrderGroup, status) => {
    const orderGroups = await this.getData('orders', status, 'orderGroups')
    orderGroups.push(newOrderGroup)
    this.setDoc('orders', status, { orderGroups: orderGroups })
  }
  addAsOpenOrderGroup = async (orders, groupId, totalPrice) => {
    if (!orders.length) return
    const orderGroup = {
      orders: orders.map(el => this.filterOrder(el, groupId)),
      groupId,
      date: new Date(),
      status: 'open',
      totalPrice,
    }
    this.setOrderGroups(orderGroup, 'open')
  }
  filterOrder = (order, groupId) => {
    const filteredOrder = {
      id: order.id,
      name: order.name,
      price: order.price,
      section: order.section,
      priceFixer: order.priceFixer || null,
      count: order.count || 1,
      status: 'open',
      date: new Date(),
      groupId,
    }
    return filteredOrder
  }
  getProducts = async () => {
    const collectionsRef = await this.getDocs('products')
    const collectionsWithData = []
    const collections = collectionsRef.docs.map(doc => doc.data().data)
    collectionsRef.docs.map(doc => doc.id).forEach((id, index) => {
      collectionsWithData[id] = collections[index]
      collectionsWithData[id].forEach((product, index) => {
        product.document = id
      })
    })
    return collectionsWithData
  }
  getDocumentProducts = async (document) => {
    const products = await this.getData('products', document, 'data')
    return products
  }

  getProductGroups = async () => {
    const collectionsRef = await this.getDocs('products')
    const productGroups = collectionsRef.docs.map(doc => doc.id)
    return productGroups
  }
  createNewGroupInProduct = async (documentName, startElement) => {
    const docRef = await this.setDoc('products', documentName, startElement)
    return docRef
  }

  getProduct = async (document, productId) => {
    const documentRef = await this.getDocumentProducts(document)
    const product = await documentRef?.find(el => el.id === productId)
    if (product) {
      if (product.imageAvailable) {
        product.url = await this.getImgUrl(productId)
      }
      return product
    }
    return 'Product not found'
  }

  insertAt (array, index, element) {
    array.splice(index, 0, element)
  }

  async addVakImage (vakName, image) {
    const imageName = 'vak_' + vakName
    if (!image) {
      await this.deleteImage(imageName)
      return
    }
    const storageRef = await this.getStorageRef(imageName)
    const snapshot = await this.uploadToStore(storageRef, image)
    return snapshot
  }

  async getImage (imageName) {
    const storageRef = await this.getImgUrl(imageName)
    return storageRef
  }

  async getVakImage (imageName) {
    const image = await this.getImage('vak_' + imageName)
    return image
  }

  async uploadProductImg (data) {
    const {
      image,
      product,
    } = data
    const storageRef = await this.getStorageRef(product.id + '')
    const snapshot = await this.uploadToStore(storageRef, image)
    return snapshot
  }

  setProduct = async (document, product, image = undefined) => {
    if (image !== 'dontRemove') {
      product.imageAvailable = !!image
      if (!image) {
        await this.deleteImage(product.id)
      } else {
        if (image) {
          await this.uploadProductImg({
            image,
            product,
          })
        }
      }
    }
    const products = await this.getDocumentProducts(document)
    const foundProduct = products.find(el => el.id == product.id)
    if (foundProduct) {
      let productIndex = 0
      products.forEach((el, index) => {
          if (el.id === product.id) {
            productIndex = index
          }
        },
      )
      const newDocument = products.filter(el => el.id != product.id)
      const formattedOrder = this.$filter.formatProductBeforeStoring(product)
      this.insertAt(newDocument, productIndex, formattedOrder)
      return await this.setDocData('products', document, { data: newDocument })
    }
    return 'Product not found'
  }

  async setGroupStatus (orderGroup, status) {
    try {
      const orderGroupCopy = { ...orderGroup }
      orderGroupCopy.status = status
      orderGroupCopy.orders = orderGroupCopy.orders.map(el => {
        return {
          ...el,
          status: status,
        }
      })

      const oldPlaceDocument = await this.getData('orders', orderGroup.status, 'orderGroups')
      const filteredOldPlaceDocument = oldPlaceDocument.filter(el => el.groupId !== orderGroup.groupId)
      await this.setDocData('orders', orderGroup.status, { orderGroups: filteredOldPlaceDocument })

      const newPlaceDocument = await this.getData('orders', status, 'orderGroups')
      newPlaceDocument.unshift(orderGroupCopy)
      await this.setDocData('orders', status, { orderGroups: newPlaceDocument })

      orderGroup.status = status
      orderGroup.orders = orderGroup.orders.map(el => {
        return {
          ...el,
          status: status,
        }
      })
      return true
    } catch (e) {
      // console.log(e)
      return false
    }
  }

  async changeGroupName (from, to) {
    const migrationData = await this.#getInProducts(from, 'data')
    await this.setDoc('products', to, { data: migrationData })
    await this.deleteDoc('products', from)
    return to
  }

  async deleteProductDocument (document) {
    await this.deleteDoc('products', document)
  }

  async createNewProduct (document, model) {
    try {
      let documentData = await this.#getInProducts(document, 'data')
      if (!documentData) return false
      const productData = {
        ...model,
        section: model.section.toLocaleLowerCase(),
        price: model.priceNumber,
        id: this.generateId(5),

      }
      documentData.reverse().push(productData)
      documentData = documentData.reverse()
      await this.setDocData('products', document, { data: documentData })
      return {
        documentData,
        productData,
      }
    } catch {
      return false
    }
  }

  async deleteProduct (document, id) {
    let documentData = await this.#getInProducts(document, 'data')
    documentData = documentData.filter(product => product.id !== id)
    await this.setDocData('products', document, { data: documentData })
  }

  async updateUser (userUid, userBlock) {
    try {
      if (!userUid || !userBlock) throw new Error('Invalid parameters')
      const user = await this.getStoreUser(userUid)
      user.user = {
        ...user.user,
        ...userBlock,
      }
      await this.setDocData('users', userUid, user)
      return user
    } catch (err) {
      console.log(err)
      throw new Error(err)
    }
  }

  getMemberschips = async (uid) => {
    const user = await this.getStoreUser(uid)
    const memberschips = (await this.getAbonnementen()).filter(subscription => user.memberschips.find(id => id.id === subscription.id))
    return memberschips
  }

  async getMemberschip (id) {
    const memberships = await this.getAbonnementen()
    const membership = memberships.find((el) => el.id === id)
    if (membership) {
      if (!membership.activated) {
        membership.currentStatus = 'stillNotActive'
        return membership
      }
      const startSeconds = membership.start.seconds
      const endSeconds = membership.end.seconds
      const currentSeconds = (new Date()).getTime() / 1000
      if (startSeconds <= currentSeconds && endSeconds >= currentSeconds) {
        membership.currentStatus = 'active'
      } else if (endSeconds < currentSeconds) {
        membership.currentStatus = 'finished'
      }
      return membership
    }
  }

  async populateMemberships (user) {
    const membershipsRefs = user.memberschips
    const userMemberships = []
    for (const ref of membershipsRefs) {
      const membership = await this.getMemberschip(ref.id)
      if (membership) userMemberships.push(membership)
    }
    user.memberschips = userMemberships
    await userMemberships.forEach(membership => {
      if (!membership.activated) return
      const startSeconds = membership.start.seconds
      const endSeconds = membership.end.seconds
      const currentSeconds = (new Date()).getTime() / 1000

      if (startSeconds <= currentSeconds && endSeconds >= currentSeconds) {
        user.activeMemberschip = membership
      }
    })
    return user
  }

  async createTimeBlock (timeBlock) {
    const {
      start,
      end,
      weeklySchedule,
    } = timeBlock
    const id = this.generateId(36)
    const timeBlockData = {
      orderRefs: [],
      status: 'open', // open/finished/removed
      type: weeklySchedule ? 'weekly' : 'once',  // once/weekly,
      start,
      end,
      id, // document id
    }
    return await this.setDoc(weeklySchedule ? 'timeWeekly' : 'timeOnce', id, timeBlockData)
  }

  async getTimeOnce () {
    const timeOnceRefs = await this.getDocs('timeOnce')
    const timeOnceDocs = timeOnceRefs.docs.map(doc => doc.data())
    return timeOnceDocs
  }

  async getTimeWeekly () {
    const timeWeeklyRefs = await this.getDocs('timeWeekly')
    const timeWeeklyDocs = timeWeeklyRefs.docs.map(doc => doc.data())
    return timeWeeklyDocs
  }

  async getTimeBlocks () {
    const timeOnce = await this.getTimeOnce()
    const timeWeekly = await this.getTimeWeekly()
    const timeBlocks = []
    timeBlocks.push(...timeOnce)
    timeBlocks.push(...timeWeekly)
    return timeBlocks
  }

  async getTimeBlock (timeBlockId) {
    const timeBlocks = await this.getTimeBlocks()
    return timeBlocks.filter((timeBlock) => timeBlock.id === timeBlockId)
  }

  async updateStatus (timeBlock, newStatus) {
    const collection = timeBlock.type === 'weekly' ? 'timeWeekly' : 'timeOnce'
    const foundedTimeBlock = await this.getData(collection, timeBlock.id)
    if (!foundedTimeBlock) throw new Error('Timeblock not found')
    foundedTimeBlock.status = newStatus
    if (newStatus === 'removed') {
      foundedTimeBlock.removedAt = new Date()
    }
    return await this.setDocData(collection, timeBlock.id, foundedTimeBlock)
  }

  async removeOrSetAsRemoved (timeBlock) {
    if (!timeBlock.orderRefs.length && timeBlock.type !== 'weekly') {
      await this.deleteDoc(timeBlock.type === 'weekly' ? 'timeWeekly' : 'timeOnce', timeBlock.id)
      return 'removed'
    }
    await this.updateStatus(timeBlock, 'removed')
    return 'set as removed'

  }

  async setAsFinished (timeBlock) {
    return await this.updateStatus(timeBlock, 'finished')
  }

  async setAsOpen (timeBlock) {
    return await this.updateStatus(timeBlock, 'open')
  }

  async getTimeBlockOrderGroups (timeBlock) {
    const timeBlockGroupOrders = []
    for (let ref of timeBlock.orderRefs) {
      const orderGroup = await this.getOrderGroup(ref.groupId)
      if(orderGroup && orderGroup.status === 'open'){
        timeBlockGroupOrders.push(orderGroup)
      }
    }
    return timeBlockGroupOrders
  }

  async updateOrderGroup (orderGroupId, newOrderGroup) {
    let orderGroups = await this.getData('orders', newOrderGroup.status, 'orderGroups')
    orderGroups = orderGroups.filter(orderGroup => orderGroup.groupId !== orderGroupId);
    orderGroups.push(newOrderGroup)
    return await this.setDocData('orders', newOrderGroup.status, { orderGroups: orderGroups })
  }

  async updateOrderGroupCode (orderGroupId, code = null) {
    const foundOrderGroup = await this.getOrderGroup(orderGroupId);
    foundOrderGroup.code = code;
    return await this.updateOrderGroup(orderGroupId, foundOrderGroup)
  }
  async getTransactionStatus(transactionId) {
    const user = await this.getUserData(this.getCurrentUserUid());
    if (!user) return;
    const status = await axios({
      method: 'post',
      url: 'https://api.stomerijetten-leur.nl/api/v1/payment/transaction_status',
      data: {
        uid: user.uid,
        token: user.token,
        transactionId,
      }
    });
    return status.data.data
  }
}

export default StomerijAuth
