const firebaseConfig = {
  apiKey: "AIzaSyBm_5Vhd2hMWHFMLMeRGA-8l_inRMXXQPU",
  authDomain: "stomerij101.firebaseapp.com",
  projectId: "stomerij101",
  storageBucket: "stomerij101.appspot.com",
  messagingSenderId: "682758630713",
  appId: "1:682758630713:web:34993b72e70d31a62f8ce8",
  measurementId: "G-MLFB2PKC5W"
};

export default firebaseConfig;
