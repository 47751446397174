import firebaseConfig from './firebaseConfig.js'
import { initializeApp } from 'firebase/app'
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import Filters from './../filters/FilterBase'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'

class Auth {
  #firebaseConfig
  #app
  #db
  #auth
  #storage

  constructor () {
    this.#firebaseConfig = firebaseConfig
    this.#app = initializeApp(this.#firebaseConfig)
    this.#db = getFirestore(this.#app)
    this.#auth = getAuth()
    this.#storage = getStorage()
    this.$filter = new Filters()
  }

  getApp () {
    return this.#app
  }

  getDb () {
    return this.#db
  }

  getAuth () {
    return this.#auth
  }

  getStorage () {
    return this.#storage
  }

  async getStorageRef (child) {
    const storageRef = await ref(this.getStorage(), child)
    return storageRef
  }

  async uploadToStore (storageRef, file) {
    const snapshot = await uploadBytes(storageRef, file)
    return snapshot
  }

  async getImgUrl (fileName) {
    const reference = await this.getStorageRef(fileName)
    try {
      const url = await getDownloadURL(reference)
      return url
    } catch (e) {
      return undefined
    }
  }

  async deleteImage (fileName) {
    const reference = await this.getStorageRef(fileName)
    return await deleteObject(reference)
  }

  generateId = length => {
    return nanoid(length)
  }

  getCollectionRef = collName => {
    const collRef = collection(this.#db, collName)
    return collRef
  }
  #getDocumentRef = (collName, docName) => {
    const docRef = doc(this.#db, collName, docName)
    return docRef
  }

  getDoc = async (collName, docName) => {
    const documentRef = this.#getDocumentRef(collName, docName)
    const doc = await getDoc(documentRef)
    return doc
  }
  setDoc = async (collName, docName, data = {}) => {
    const documents = await this.getDocs(collName)
    const allDocList = documents.docs.map(el => el.id)
    const foundDoc = allDocList.find(el => el === docName)
    if (foundDoc) {
      return 'Document already exist'
    } else {
      const docRef = this.#getDocumentRef(collName, docName)
      return await setDoc(docRef, data)
    }
  }
  getDocs = async collName => {
    const collectionRef = this.getCollectionRef(collName)
    const docs = await getDocs(collectionRef)
    return docs
  }
  deleteDoc = async (collName, docName) => {
    return await deleteDoc(this.#getDocumentRef(collName, docName))
  }
  getDocs = async collName => {
    const collectionRef = this.getCollectionRef(collName)
    const docs = await getDocs(collectionRef)
    return docs
  }

  setDocData = async (collName, docName, data = {}) => {
    const docRef = this.#getDocumentRef(collName, docName)
    return await setDoc(docRef, data)
  }

  getData = async (collName, docName, fieldName = undefined) => {
    const doc = await this.getDoc(collName, docName)
    let data = doc
    if (fieldName) {
      data = await doc.get(fieldName)
    } else {
      data = data.data()
    }
    return data
  }

  getUser = async (uid) => {
    const user = await this.#auth.getUser(uid)
    return user
  }

  getCurrentUser = () => {
    const currentUser = this.#auth.currentUser
    return currentUser
  }

  // Users
  #getInUsers = async (collName, fieldName = undefined) => {
    const data = await this.getData("users", collName, fieldName);
    return data;
  };
  getUserData = async (uid) => {
    const user = await this.#getInUsers(uid);
    return user;
  };

  getCurrentUserUid = () => {
    const currentUser = this.getCurrentUser()
    const currentUserUid = currentUser.uid
    return currentUserUid
  }

}

export default Auth
